import React, { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MainCard from '../../../components/MainCard';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { fontStyle } from '../../dashboard/utils';
import BacteriaAnalyzeColors from './BacteriaAnalayzeColors';
import BacterialMonitoringChart from './BacteriaMonitoringChart';
import { useTranslation } from 'react-i18next';

// Reorder function for drag-and-drop
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

function BacteriaMonitoring() {
  const { t } = useTranslation()
  const initialData = [
    { sensor: 'TAB', value: '27/80', condition: 'unhealthy' },
    { sensor: 'PM10', value: '3,298', condition: 'healthy' },
    { sensor: 'PM25', value: '2m 34s', condition: 'unhealthy' },
    { sensor: 'CO', value: '64%', condition: 'normal' },
    { sensor: 'CO2', value: '86%', condition: 'unhealthy' },
    { sensor: 'TVOC', value: '+34%', condition: 'normal' },
    { sensor: 'CH2O', value: '28%', condition: 'healthy' },
    { sensor: 'NO2', value: '80%', condition: 'unhealthy' },
  ];

  const [data] = useState(initialData); // Keep data constant
  const [bacteries, setBacteries] = useState([1, 2, 3, 4]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newBacteries = reorder(bacteries, result.source.index, result.destination.index);
    setBacteries(newBacteries);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" type="ROW">
        {(provided) => (
          <Stack sx={{ height: { xs: '700px', md: '967px' }, overflowY: 'scroll' }} {...provided.droppableProps} ref={provided.innerRef}>
            {bacteries.map((bactery, i) => (
              <Draggable key={bactery} draggableId={`bactery-${bactery}`} index={i}>
                {(provided) => (
                  <Box ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    sx={{
                      border: '1px solid #e0e0e0',
                      borderRadius: 2,
                      mb: i === bacteries.length - 1 ? 0 : 2,
                      bgcolor: '#e0e0e0'
                    }}>
                    <Box sx={{ ...fontStyle, fontSize: '0.9rem', mt: 0.8, mb: 0.6, mx: 1 }}>ID{bactery}OC</Box>
                    <Box sx={{ display: { xs: 'block', md: 'flex' }, gap: 2, }}>
                      <Stack sx={{ width: { xs: '100%', md: '35%' } }}>
                        <MainCard
                          sx={{
                            p: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            position: 'relative'
                          }}
                          content={false}
                        >
                          <DragIndicatorIcon sx={{ rotate: '90deg', position: 'absolute', top: 5, left: 4 }} />
                          <Typography sx={{ ...fontStyle, color: 'gray', }} variant="h5">
                            {t('bacteria_level')}
                          </Typography>
                          <Typography sx={{ ...fontStyle, fontSize: '1.4rem' }} variant="h5">
                            30
                          </Typography>

                          <Typography sx={{ ...fontStyle, fontSize: '1rem', color: 'gray' }} variant="h5">
                            {t('unhealthy')}
                          </Typography>
                          <BacteriaAnalyzeColors />

                          <Box sx={{ width: '100%' }}>
                            <BacterialMonitoringChart />
                          </Box>

                        </MainCard>
                      </Stack>
                      <Box sx={{ width: { xs: '100%', md: '65%' }, display: 'flex', flexWrap: 'wrap' }}>
                        {data.map((el, index) => (
                          <MainCard
                            key={index}
                            sx={{
                              flex: '1 1 33.3%', // Flex-grow: 1, Flex-shrink: 1, Flex-basis: 25%
                              boxSizing: 'border-box',
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                              textAlign: 'center',
                              py: 1
                            }}
                            content={false}
                          >
                            <Typography sx={{ ...fontStyle, fontSize: '0.8rem', fontWeight: 'bold' }} variant="h5">
                              {el.sensor}
                            </Typography>
                            <Typography sx={{ ...fontStyle, fontSize: '1.2rem' }} variant="h5">
                              {el.value}
                            </Typography>
                            <Box>
                              <Typography sx={{ ...fontStyle, fontSize: '0.8rem', color: 'gray' }} variant="h5">
                                {t(el.condition)}
                              </Typography>
                              <BacteriaAnalyzeColors />
                            </Box>
                          </MainCard>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Stack>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default BacteriaMonitoring;
