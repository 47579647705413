import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ReactApexChart from 'react-apexcharts';
import { fontStyle } from '../utils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getAuthToken } from '../../../utils/jwtUtils';

// Define AQI thresholds for each category
const AQI_THRESHOLDS = {
  good: 50,
  moderate: 100,
  unhealthy_for_sensitive_groups: 150,
  unhealthy: 200,
  very_unhealthy: 300,
  hazardous: Infinity,
};

// Function to categorize AQI values
const categorizeAQI = (aqi) => {
  if (aqi <= AQI_THRESHOLDS.good) return 'good';
  if (aqi <= AQI_THRESHOLDS.moderate) return 'moderate';
  if (aqi <= AQI_THRESHOLDS.unhealthy_for_sensitive_groups) return 'unhealthy_for_sensitive_groups';
  if (aqi <= AQI_THRESHOLDS.unhealthy) return 'unhealthy';
  if (aqi <= AQI_THRESHOLDS.very_unhealthy) return 'very_unhealthy';
  return 'hazardous';
};

// ==============================|| MONTHLY PIE CHART ||============================== //

export default function MonthlyPieChart({ date }) {
  const theme = useTheme();
  const token = getAuthToken();

  const { currentFacility } = useSelector((state) => state.facility);
  const { startDate, endDate } = date;
  const { t } = useTranslation();

  const info = theme.palette.info.light;

  // Initial chart options
  const initialPieChartOptions = {
    chart: {
      type: 'pie',
      height: 340,
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: true,
      style: {
        ...fontStyle,
      },
    },
    legend: {
      show: true,
      position: 'bottom',
      labels: {
        useSeriesColors: false,
      },
    },
    colors: ['#00E396', '#FEB019', '#775DD0', '#26a69a', '#546E7A', '#FF4560']
  };

  const [series, setSeries] = useState([0, 0, 0, 0, 0, 0]); // Initial values set to 0
  const [options, setOptions] = useState(initialPieChartOptions);

  const getPieChartData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/culturingAQIPie`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ startDate, endDate, facilityId: currentFacility.facilityId }),
      });

      const data = await res.json();

      if (!res.ok) {
        // Handle error or empty response
        setSeries([0, 0, 0, 0, 0, 0]);
        return;
      }

      // Categorize and count AQI values
      const counts = {
        good: 0,
        moderate: 0,
        unhealthy_for_sensitive_groups: 0,
        unhealthy: 0,
        very_unhealthy: 0,
        hazardous: 0
      };

      data.forEach(item => {
        const category = categorizeAQI(item.aqi);
        counts[category]++;
      });

      // Set series with categorized counts
      setSeries([
        counts.good,
        counts.moderate,
        counts.unhealthy_for_sensitive_groups,
        counts.unhealthy,
        counts.very_unhealthy,
        counts.hazardous
      ]);

    } catch (error) {
      console.error('Error fetching data:', error);
      setSeries([0, 0, 0, 0, 0, 0]);
    }
  };

  useEffect(() => {
    getPieChartData();
  }, [date, currentFacility]);

  useEffect(() => {
    // Update chart labels based on current language
    setOptions((prevState) => ({
      ...prevState,
      labels: [
        t('good'),
        t('moderate'),
        t('unhealthy_for_sensative_groups'),
        t('unhealthy'),
        t('very_unhealthy'),
        t('hazardous')
      ]
    }));
  }, [t]);

  return (
    <Box id="chart" sx={{ bgcolor: 'transparent', height: '100%', mt: 4.5 }}>
      <ReactApexChart options={options} series={series} type="pie" height={340} />
    </Box>
  );
}
