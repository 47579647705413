import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAlert } from '../redux/alert/alertSlice';
import { getAuthToken } from '../utils/jwtUtils';
import { FormControl, MenuItem, Select } from '@mui/material';
import { fontStyle } from '../pages/dashboard/utils';
import { useTranslation } from 'react-i18next';
import { setFacility } from '../redux/facility/facilitySlice';
import { useSelector } from 'react-redux';

function SelectMenu() {
  const { t } = useTranslation();
  const { currentUser } = useSelector(state => state.user)
  const { currentFacility } = useSelector(state => state.facility)

  const [facilities, setFacilities] = useState([]);
  const dispatch = useDispatch();
  const token = getAuthToken();

  const handleChange = (e) => {
    dispatch(setFacility(facilities.find(facility => facility.facilityId == e.target.value)))
  };

  const fetchFacilities = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/facilities?company=${currentUser.companyName}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await res.json();

      if (!res.ok) {
        dispatch(setAlert({ type: 'error', message: data.message }));
        return;
      }

      const modifiedData = data.map(facility => ({ ...facility, facilityId: facility.facilityId.charAt(0) + facility.facilityId.slice(1).toLowerCase() }))

      setFacilities([{ facilityName: 'Total', facilityId: 'total' }, ...modifiedData]);
    } catch (error) {
      dispatch(setAlert({ type: 'error', message: error.message }));
    }
  };

  useEffect(() => {
    fetchFacilities();
  }, [dispatch, token]);


  return (
    <FormControl sx={{ minWidth: 150 }} size="small">
      {facilities.length > 0 && <Select
        id="demo-select-small"
        value={currentFacility.facilityId}
        onChange={handleChange}
        displayEmpty
        renderValue={
          currentFacility.facilityId !== '' ? undefined : () => <span>{t('choose_facility')}</span>
        }
        sx={{ ...fontStyle, fontSize: '0.8rem', mx: 2 }}
      >
        {facilities.map((select, i) => (
          <MenuItem sx={{ ...fontStyle }} key={i} value={select.facilityId}>
            {t(select.facilityName)}
          </MenuItem>
        ))}
      </Select>}

    </FormControl>
  );
}

export default SelectMenu;
