
export const getAuthToken = () => {
  return window.localStorage.getItem('auth_token');
};

export const removeAuthToken = () => {
  window.localStorage.removeItem("auth_token");;
};

export const setAuthToken = (token) => {
  if (token !== null) {
    window.localStorage.setItem('auth_token', token);
  } else {
    window.localStorage.removeItem('auth_token');
  }
};


export const verifyAuthToken = async (token) => {
  try {
    if (!token) return false;

    const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/auth/verifyToken`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    const data = await res.json();
    if (!res.ok) {
      removeAuthToken(token)
      return false
    }
    return data; // Assuming your API returns a { valid: true/false } response
  } catch (error) {
    console.error('Token verification failed', error);
    return false;
  }
};

