import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function DisplayDateTable({ title, periodData }) {
  const { t } = useTranslation()
  return (
    <Box sx={{ p: { xs: 2, sm: 0.4, md: 2 } }}>
      <Typography variant="h6" align="center" sx={{ fontWeight: 500 }}>
        {t(title.text)}
      </Typography>
      <Box sx={{ mx: 'auto', listStyle: 'none', p: 0 }} component="ul">
        {periodData.map((el, elId) => (
          <Box
            key={elId}
            component="li"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              borderBottom: '1px solid rgba(0, 0, 255, 1)',
              mt: 2,
              fontWeight: 'bold',
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>{el.item}</Typography>
            <Typography sx={{ fontWeight: 'bold' }}>{el.date}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default DisplayDateTable;
