import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  type: '',
  message: ''
};

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    clearAlert: (state) => {
      state.alertType = '';
      state.message = '';
    },
    setAlert: (state, action) => {
      state.type = action.payload.type;
      state.message = action.payload.message;
    }
  },
});


export const {
  setAlert, clearAlert
} = alertSlice.actions;
export default alertSlice.reducer;