import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  message: '',
  title: '',
};

const popupSlice = createSlice({
  name: "popup",
  initialState,
  reducers: {
    closePopup: (state) => {
      state.open = false;
      state.message = '';
      state.title = '';
    },
    openPopup: (state, action) => {
      state.open = true;
      state.message = action.payload.message;
      state.title = action.payload.title;
    }
  },
});


export const {
  openPopup, closePopup
} = popupSlice.actions;
export default popupSlice.reducer;