import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import Typography from '@mui/material/Typography';
import BarChartContainer from './components/BarChart';
import DisplayDateTable from './components/DisplayDateTable';
import DisplayBacteriaData from './components/DisplayBacteriaData';
import DisplayBacteriaDegree from './components/DisplayBacteriaDegree';
import { getAuthToken } from '../../utils/jwtUtils';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../redux/alert/alertSlice';
import MainCard from '../../components/MainCard';
import { useTranslation } from 'react-i18next';
import { fontStyle } from '../dashboard/utils';

// Dummy data for DisplayDataTable components
const periodData = [
  { item: "TAB", date: "2024/06/18" },
  { item: "PM10", date: "2024/06/18" },
  { item: "PM2.5", date: "2024/06/18" },
  { item: "CO", date: "2024/06/18" },
  { item: "CO2", date: "2024/06/18" },
  { item: "VOC", date: "2024/06/18" },
  { item: "HCHO", date: "2024/06/18" },
  { item: "NO2", date: "2024/06/18" },
];

function Report() {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const token = getAuthToken();
  const [reportData, setReportData] = useState([])

  const handleGetReportData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/reports`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await res.json();

      if (!res.ok) {
        dispatch(setAlert({ type: 'error', message: data.message }));
      }

      setReportData(data);
    } catch (error) {
      dispatch(setAlert({ type: 'error', message: error.message }));
    }
  };

  useEffect(() => {
    handleGetReportData()
    // Set interval to fetch data every 10 minutes
    const intervalId = setInterval(() => {
      handleGetReportData();
    }, 600000); // 600000 ms = 10 minutes

    // Clean up function to clear interval
    return () => clearInterval(intervalId);
  }, [dispatch, token]);

  return (
    <Box>
      <Grid sx={{ mb: 1 }}>
        <Grid item container columnGap={2.75} sx={{ alignItems: 'center' }}>
          <Typography sx={{ ...fontStyle }} variant='h5'>
            {t('report')}
          </Typography>
          {/* Refresh Icon */}
          <IconButton
            onClick={handleGetReportData}
            sx={{
              '&:hover': {
                backgroundColor: '#e0e0e0',
              }
            }}
          >
            <RefreshIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Box sx={{ display: { xs: 'block', lg: 'flex' }, gap: 2 }}>
        <Grid container spacing={2} sx={{ width: { xs: 'auto', lg: '75%' } }}>
          <Grid item xs={12} md={6} lg={4}>
            <MainCard sx={{ padding: '16px', backgroundColor: '#bbdefb', height: '100%' }}>
              <DisplayBacteriaData currentReportInfo={reportData} />
            </MainCard>
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <MainCard sx={{ padding: '12px', backgroundColor: '#bbdefb', height: '100%' }}>
              <DisplayBacteriaDegree currentReportInfo={reportData} />
            </MainCard>
          </Grid>

          <Grid item xs={12} sm={6}>
            <MainCard sx={{ padding: '16px', backgroundColor: '#ffffff' }}>
              <DisplayDateTable title={{ text: "monthly_exceedance" }} periodData={periodData} />
            </MainCard>
          </Grid>

          <Grid item xs={12} sm={6}>
            <MainCard sx={{ padding: '16px', backgroundColor: '#ffffff' }}>
              <DisplayDateTable title={{ text: "yearly_exceedance" }} periodData={periodData} />
            </MainCard>
          </Grid>
        </Grid>
        <MainCard sx={{ width: { xs: 'auto', lg: '25%' }, p: '16px', backgroundColor: '#ffffff', mt: { xs: 2, lg: 0 } }}>
          <BarChartContainer />
        </MainCard>
      </Box>
    </Box>
  );
}

export default Report;
