import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  postAnswerFailure,
  postAnswerStart,
  postAnswerSuccess,
} from '../../redux/answer/answerSlice';
import { openPopup } from '../../redux/popup/popupSlice';
import { setAlert } from '../../redux/alert/alertSlice';
import { getAuthToken } from '../../utils/jwtUtils';

import { Box, Button, TextField, IconButton } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from 'react-i18next';

function Reply() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currentQuestion } = useSelector((state) => state.question);
  const { currentUser } = useSelector((state) => state.user);
  const token = getAuthToken();
  const [reply, setReply] = useState({ answer: '' });
  const [isEmpty, setIsEmpty] = useState(true);

  const handleReplyChange = (e) => {
    const value = e.target.value;
    setIsEmpty(value.trim() === '');
    setReply({ ...reply, [e.target.name]: value, userId: currentUser?.id, questionId: currentQuestion?.id });
  };

  const handleSubmitReply = async (e) => {
    e.preventDefault();
    if (isEmpty) return;
    try {
      dispatch(postAnswerStart());
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/answers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(reply),
      });
      const data = await res.json();

      if (!res.ok) {
        dispatch(openPopup({ title: 'Do you want to reply?', message: data.message }));
        dispatch(postAnswerFailure(data.message));
        return;
      }

      dispatch(postAnswerSuccess(data));
      dispatch(setAlert({ type: 'success', message: 'Replied successfully!' }));
    } catch (err) {
      dispatch(postAnswerFailure(err.message));
    } finally {
      setReply({ answer: '' });
      setIsEmpty(true);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        px: 0,
        py: 1,
        borderRadius: 1,
        bgcolor: 'rgba(248, 249, 250, 1)',
        borderTop: '1px solid rgba(209, 213, 219, 1)',
      }}
    >
      <TextField
        required
        onChange={handleReplyChange}
        name="answer"
        id="answer"
        multiline
        rows={1}
        value={reply.answer}
        placeholder={t('message')}
        variant="outlined"
        fullWidth
        sx={{
          bgcolor: 'rgba(255, 255, 255, 1)',
          '& .MuiOutlinedInput-root': {
            borderRadius: 1,
          },
        }}
      />

      <IconButton
        onClick={handleSubmitReply}
        type="button"
        disabled={isEmpty}
        sx={{
          ml: 1,
          color: 'rgba(37, 99, 235, 1)',
          '&:hover': {
            bgcolor: 'rgba(219, 234, 254, 1)',
          },
        }}
      >
        <SendIcon />
      </IconButton>
    </Box>
  );
}

export default Reply;
