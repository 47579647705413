import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function SensorMultyChart({ data }) {

  const options = {
    responsive: true,
    scales: {
      x: {
        display: false,
      },
      y: {
        beginAtZero: true,
        reverse: false,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
        },
      },
      title: {
        display: false,
      },
    },
  };

  // Check if data is undefined or does not have labels or datasets
  if (!data || !data.labels || !data.datasets) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ marginTop: '10px', width: '100%' }}>
      <Line options={options} data={data} />
    </div>
  );
}

export default SensorMultyChart;
