import { Box, ImageListItem, Stack, Typography } from '@mui/material'
import React from 'react'
import MainCard from '../../../components/MainCard'
import { fontStyle } from '../../dashboard/utils'
import BacteriaAnalyzeColors from './BacteriaAnalayzeColors'
import BacteriaAnalyzeTrend from './bacteriaAnalyzeTrend'
import ImagesCarousel from './ImagesCarousel'
import { useTranslation } from 'react-i18next'
import bacteriaPic1 from './../../../assets/bacteria_pictures/analyzed_시험편1.jpeg'
import bacteriaPic2 from './../../../assets/bacteria_pictures/analyzed_시험편2.jpeg'
import bacteriaPic3 from './../../../assets/bacteria_pictures/analyzed_시험편3.jpeg'
import bacteriaPic4 from './../../../assets/bacteria_pictures/analyzed_시험편4.jpeg'
import bacteriaPic5 from './../../../assets/bacteria_pictures/analyzed_시험편5.jpeg'
import bacteriaPic6 from './../../../assets/bacteria_pictures/analyzed_시험편6.jpeg'
import bacteriaPic7 from './../../../assets/bacteria_pictures/analyzed_시험편7.jpeg'
import bacteriaPic8 from './../../../assets/bacteria_pictures/analyzed_시험편8.jpeg'
import bacteriaPic9 from './../../../assets/bacteria_pictures/analyzed_시험편9.jpeg'
import bacteriaPic10 from './../../../assets/bacteria_pictures/analyzed_시험편10.jpeg'


const bacteriaImgs = [
  {
    src: bacteriaPic1,
    hour: 4
  },
  {
    src: bacteriaPic2,
    hour: 3
  },
  {
    src: bacteriaPic3,
    hour: 2
  },
  {
    src: bacteriaPic4,
    hour: 1
  },
  {
    src: bacteriaPic5,
    hour: 2
  },
  {
    src: bacteriaPic6,
    hour: 3
  },
  {
    src: bacteriaPic7,
    hour: 4
  },
  {
    src: bacteriaPic8,
    hour: 5
  },
  {
    src: bacteriaPic9,
    hour: 2
  },
  {
    src: bacteriaPic10,
    hour: 3
  },
]

function BacteriaAnalyze() {
  const { t } = useTranslation()

  return (
    <Stack>
      {/* Image and Unhealthy level */}
      <Stack>
        <Typography sx={{ ...fontStyle, mb: 1 }} variant='h5'>
          {t('bacteria_level')}
        </Typography>
        <Box sx={{ display: { xs: 'block', sm: 'flex' }, gap: 2 }}>
          <Stack sx={{ width: { xs: '100%', sm: '50%' }, mb: { xs: 2, sm: 0 } }}>
            <MainCard sx={{ height: '100%', px: 2, py: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} content={false}>
              <Typography sx={{ ...fontStyle, lineHeight: 1, fontSize: '1.2rem', color: 'gray' }} variant='h5'>
                {t('bacteria_level')}
              </Typography>
              <Typography sx={{ ...fontStyle, fontSize: '3rem', my: 1 }} variant='h5'>
                30
              </Typography>

              <Typography sx={{ ...fontStyle, fontSize: '1.2rem', color: 'gray', mb: 1 }} variant='h5'>
                {t('unhealthy')}
              </Typography>
              <BacteriaAnalyzeColors />
            </MainCard>
          </Stack>
          <Stack sx={{ width: { xs: '100%', sm: '50%' } }}>
            <MainCard sx={{ textAlign: 'center' }} content={false}>
              <ImageListItem
                sx={{ overflow: 'hidden' }} // Set height and ensure no overflow
              >
                <img
                  src={bacteriaPic8}
                  alt={'Colony Image'}
                  loading="lazy"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }} // Make sure the image covers the container
                />
              </ImageListItem>
              <Typography sx={{ ...fontStyle, fontSize: '1.5rem', my: 1 }} variant='h5'>
                10
                <Typography
                  sx={{
                    ...fontStyle,
                    fontSize: '1rem',
                    color: 'gray',
                    display: 'inline' // Ensure this stays inline with the previous text
                  }}
                  component='span' // Render as a span element
                  variant='body1'
                >
                  CFU
                </Typography>
              </Typography>
            </MainCard>
          </Stack>
        </Box>
      </Stack >

      {/* Bacteria  Line Chart */}
      < Stack >
        <Typography sx={{ ...fontStyle, mt: 1.5 }} variant='h5'>
          {t('time_laps')}
        </Typography>
        <Box>
          <BacteriaAnalyzeTrend />
        </Box>
      </Stack >

      {/* Bacteria  Images Carousel */}
      < Stack >
        <Typography sx={{ ...fontStyle, my: 1.5 }} variant='h5'>
          {t('bacteria_pictures')}
        </Typography>
        <Box>
          <ImagesCarousel data={bacteriaImgs} />
        </Box>
      </Stack >

    </Stack >
  )
}

export default BacteriaAnalyze
