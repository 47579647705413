import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentAnswer: null,
  error: null,
  loading: false,
};

const answerSlice = createSlice({
  name: "answer",
  initialState,
  reducers: {
    postAnswerStart: (state) => {
      state.loading = true;
    },
    postAnswerSuccess: (state, action) => {
      state.currentAnswer = action.payload;
      state.loading = false;
      state.error = null;
    },
    postAnswerFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    updateAnswerStart: (state) => {
      state.loading = true;
    },
    updateAnswerSuccess: (state, action) => {
      state.currentAnswer = action.payload;
      state.loading = false;
      state.error = null;
    },
    updateAnswerFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    deleteAnswerStart: (state) => {
      state.loading = true;
    },
    deleteAnswerSuccess: (state, action) => {
      state.currentAnswer = null;
      state.loading = false;
      state.error = null;
    },
    deleteAnswerFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  postAnswerStart,
  postAnswerSuccess,
  postAnswerFailure,
  updateAnswerStart,
  updateAnswerSuccess,
  updateAnswerFailure,
  deleteAnswerStart,
  deleteAnswerSuccess,
  deleteAnswerFailure,

} = answerSlice.actions;
export default answerSlice.reducer;