/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { addDays, addMonths, addYears } from 'date-fns';
import { Button, FormControl, MenuItem, Select, Typography } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { SyncLoader } from 'react-spinners';
import { dateActions, sensorActions } from './utils';
import { getAuthToken } from '../../utils/jwtUtils';
import Calendar from '../../components/RDR';
import moment from 'moment';
import TrendChart from './components/TrendChart';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../redux/alert/alertSlice';
import Spinner from '../../components/Spinner';
import { fontStyle } from '../dashboard/utils';
import { useTranslation } from 'react-i18next';

function TrendPage() {
  const { t } = useTranslation()
  const [graphLabels, setGraphLabels] = useState([]);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState({
    graph: false,
    rankingList: false,
  });

  const [graphData, setGraphData] = useState({
    labels: {},
    dataset: [],
    rankingList: [],
  });

  const randomColor = () => {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
  };

  const [searchData, setSearchData] = useState({
    startDate: addMonths(new Date(), -1),
    endDate: new Date(),
    dateOption: dateActions.ONE_MONTH,
    itemOption: sensorActions.TAB,
  });

  const handleDateChange = (e) => {
    const { value } = e.target;

    let startDate, endDate;

    switch (value) {
      case dateActions.ALL:
        startDate = new Date('2017-08-01');
        endDate = new Date();
        break;
      case dateActions.TODAY:
        startDate = endDate = new Date();
        break;
      case dateActions.THREE_DAYS:
        startDate = addDays(new Date(), -3);
        endDate = new Date();
        break;
      case dateActions.ONE_MONTH:
        startDate = addMonths(new Date(), -1);
        endDate = new Date();
        break;
      case dateActions.THREE_MONTHS:
        startDate = addMonths(new Date(), -3);
        endDate = new Date();
        break;
      case dateActions.SIX_MONTHS:
        startDate = addMonths(new Date(), -6);
        endDate = new Date();
        break;
      case dateActions.ONE_YEAR:
        startDate = addYears(new Date(), -1);
        endDate = new Date();
        break;
      default:
        break;
    }

    setSearchData({ ...searchData, startDate, endDate, dateOption: value });
  };

  const handleItemChange = (e) => {
    setSearchData({ ...searchData, itemOption: e.target.value });
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    if (name === 'searchDate' && value?.key === 'selection') {
      setSearchData({
        ...searchData,
        startDate: value?.startDate,
        endDate: value?.endDate,
        dateOption: '',
      });
    }
  };

  const handleSearch = () => {
    const dataParams = {
      startDate: moment(searchData?.startDate).format('YYYY-MM-DD'),
      endDate: moment(searchData?.endDate).format('YYYY-MM-DD'),

      dateOption: searchData?.dateOption || dateActions.ONE_MONTH,
      itemOption: searchData?.itemOption || sensorActions.TAB,
    };
    getGraphData({ ...dataParams });
  };

  const getGraphData = async (params) => {
    setLoading({ ...loading, graph: true, rankingList: true });
    setGraphData({ ...graphData, rankingList: [], labels: [], dataset: [] });
    const token = getAuthToken();

    const startDate = moment(params?.startDate).format('YYYY-MM-DD');
    const endDate = moment(params?.endDate).format('YYYY-MM-DD');

    try {
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/trends`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ...params, startDate, endDate }),
      });

      const data = await res.json();

      if (!res.ok) {
        dispatch(
          setAlert({
            type: 'error',
            message: data.message || 'No data found for selected dates!',
          })
        );
        setLoading({ ...loading, graph: false, rankingList: false });
        return;
      }

      setLoading({ ...loading, graph: false, rankingList: false });

      // Initialize chartData structure
      const chartData = {};
      const labels = [];

      // Prepare labels and datasets
      data.forEach((entry) => {
        const dateString = `${entry.createDttm[0]}-${entry.createDttm[1]}-${entry.createDttm[2]}`;
        if (!labels.includes(dateString)) {
          labels.push(dateString);
        }

        if (!chartData[entry.nodeId]) {
          const lineColor = randomColor();
          chartData[entry.nodeId] = {
            label: 'Node',
            data: [],
            backgroundColor: lineColor,
            borderWidth: 2,
            borderColor: lineColor,
          };
        }
        chartData[entry.nodeId].data.push(entry['sensor' + searchData.itemOption + 'Avg']);
      });

      // Sort labels chronologically
      labels.sort();

      // Extract datasets from chartData
      const datasets = Object.values(chartData);
      setGraphLabels(labels);
      setGraphData({ ...graphData, rankingList: data?.ranking || [], dataset: datasets });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading({ ...loading, search: false, graph: false, rankingList: false });
    }
  };

  useEffect(() => {
    getGraphData(searchData);
  }, []);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      {/* Header */}
      <Box sx={{ p: 2, gap: 1, alignItems: 'center', width: { xs: '100%', xl: 'auto' } }}>
        <Box sx={{
          display: 'flex', flexDirection: 'row', gap: 2, '@media (max-width: 1400px)': {
            flexDirection: 'column',
          }
        }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography
              sx={{
                borderRight: '0px !important',
                color: 'black',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                display: { xs: 'none', sm: 'block' }
              }}
            >
              {t('period')}
            </Typography>

            <ToggleButtonGroup
              size='small'
              color='secondary'
              sx={{
                fontWeight: 'fontWeightBold', display: 'block', '@media (max-width: 720px)': {
                  display: 'none',
                }
              }}
              value={searchData?.dateOption}
              exclusive
              onChange={handleDateChange}
              aria-label='defined date range'
            >
              {Object.keys(dateActions).map((key, index) => (
                <ToggleButton
                  key={`${index}-${dateActions[key]}`}
                  value={dateActions[key]}
                  aria-label='left aligned'
                  sx={{
                    color: 'black !important',
                    fontWeight: dateActions[key] === searchData.dateOption ? 'fontWeightBold' : 400,
                    minWidth: '60px',
                    borderRadius: 0,
                    '&:focus': {
                      backgroundColor: 'lightblue',
                    },
                  }}
                >
                  {t(key)}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 2,
              ml: { sm: 5, },
              '@media (max-width: 720px)': {
                display: 'block',
              },
            }}
          >
            <Calendar
              state={{
                startDate: searchData.startDate,
                endDate: searchData.endDate,
                key: 'selection',
              }}
              dateRangeProps={{
                minDate: new Date('2017-08-01'),
                maxDate: new Date(),
              }}
              setState={(e) => handleChangeInput({ target: { name: 'searchDate', value: e } })}
            />
            <Typography
              sx={{
                borderRight: '0px !important',
                color: '#999b9b',
                fontWeight: 'fontWeightBold',
                fontSize: 15,
                whiteSpace: 'nowrap',
                '@media (max-width: 750px)': {
                  mt: 1,
                },
              }}
            >
              <span style={{ color: 'red' }}>*</span>{t('trend_data_available')}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            flexDirection: 'row',
            display: 'flex',
            mt: 2,
            gap: 2,
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              flexShrink: 0,
              color: 'black',
              fontWeight: 'fontWeightBold',
              fontSize: 13,
              whiteSpace: 'nowrap',
              display: { xs: 'none', sm: 'block' }
            }}
          >
            {t('item')}
          </Typography>

          {/* Buttons for desktop */}
          <ToggleButtonGroup
            size='small'
            color='secondary'
            sx={{
              fontWeight: 'fontWeightBold', py: 1, display: 'block', '@media (max-width: 720px)': {
                display: 'none',
              },
            }}
            value={searchData?.itemOption}
            exclusive
            onChange={handleItemChange}
            aria-label='defined date range'
          >
            {Object.keys(sensorActions).map((key, index) => (
              <ToggleButton
                key={`${index}-${sensorActions[key]}`}
                value={sensorActions[key]}
                aria-label='left aligned'
                sx={{
                  color: 'black !important',
                  fontWeight: sensorActions[key] === searchData.itemOption ? 'fontWeightBold' : 400,
                  minWidth: '60px',
                  borderRadius: 0,
                  '&:focus': {
                    backgroundColor: 'lightblue',
                  },
                }}
              >
                {sensorActions[key]}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>

          {/* FORM SelectorS for mobile */}
          <FormControl sx={{
            display: 'none', '@media (max-width: 720px)': {
              display: 'block',
            },
          }
          }
            aria-label='defined date range' size="small">
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={searchData?.itemOption}
              onChange={handleItemChange}
              sx={{ ...fontStyle, }}
            >
              {Object.keys(sensorActions).map((key, index) => (
                <MenuItem
                  key={`${index}-${sensorActions[key]}`}
                  value={sensorActions[key]}
                  aria-label='left aligned'
                  sx={{ color: 'black !important' }}
                >
                  {sensorActions[key]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{
            display: 'none', '@media (max-width: 700px)': {
              display: 'block',
            }
          }}
            aria-label='defined date range' size="small">
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={searchData?.dateOption}
              onChange={handleDateChange}
              sx={{ ...fontStyle, }}
            >
              {Object.keys(dateActions).map((key, index) => (
                <MenuItem
                  key={`${index}-${dateActions[key]}`}
                  value={dateActions[key]}
                  aria-label='left aligned'
                  sx={{
                    color: 'black !important',
                    fontWeight: dateActions[key] === searchData.dateOption ? 'fontWeightBold' : 400,
                    minWidth: '60px',
                    borderRadius: 0,
                    '&:focus': {
                      backgroundColor: 'lightblue',
                    },
                  }}
                >
                  {t(key)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Btn */}
          <Button
            variant='contained'
            disabled={!searchData?.startDate || !searchData.endDate}
            onClick={handleSearch}
            sx={{
              width: 100,
              height: '100%',
              borderRadius: 3,
              m: 0,
            }}
          >
            {loading?.search ? <SyncLoader color='#FFFF' size={5} /> : t('search')}
          </Button>

        </Box>
      </Box>

      {/* Spinner */}
      {loading.graph && <Spinner />}

      {/* Chart */}
      <Box
        width='100%'
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          gap: 2,
          mt: 2,
        }}
      >
        <Box sx={{ width: { xs: '100%', sm: '80%', xl: '60%' }, mt: 4 }}>
          <TrendChart data={{ labels: graphLabels, datasets: graphData?.dataset }} />
          {graphData && graphData.dataset?.length >= 1 && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Typography
                sx={{
                  color: 'black',
                  fontSize: 14,
                  whiteSpace: 'nowrap',
                }}
              >
                {graphLabels[0]} ~ {graphLabels[graphLabels.length - 1]}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default TrendPage;
