import React, { useEffect } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import { IconButton, Tooltip, Box } from '@mui/material';
import { FaBold, FaHeading, FaItalic, FaListOl, FaListUl, FaQuoteLeft, FaRedo, FaStrikethrough, FaUnderline, FaUndo } from 'react-icons/fa';

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null;
  }

  const handleClick = (command, options) => {
    editor.chain().focus()[command](options).run();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: '5px',
        backgroundColor: 'rgba(248, 249, 250, 1)',
        borderRadius: '5px',
      }}
    >
      <Box>
        <Tooltip title="Bold" arrow>
          <IconButton
            onClick={() => handleClick('toggleBold')}
            color={editor.isActive('bold') ? 'primary' : 'default'}
          >
            <FaBold size={18} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Italic" arrow>
          <IconButton
            onClick={() => handleClick('toggleItalic')}
            color={editor.isActive('italic') ? 'primary' : 'default'}
          >
            <FaItalic size={18} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Underline" arrow>
          <IconButton
            onClick={() => handleClick('toggleUnderline')}
            color={editor.isActive('underline') ? 'primary' : 'default'}
          >
            <FaUnderline size={18} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Strikethrough" arrow>
          <IconButton
            onClick={() => handleClick('toggleStrike')}
            color={editor.isActive('strike') ? 'primary' : 'default'}
          >
            <FaStrikethrough size={18} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Heading 2" arrow>
          <IconButton
            onClick={() => handleClick('toggleHeading', { level: 2 })}
            color={editor.isActive('heading', { level: 2 }) ? 'primary' : 'default'}
          >
            <FaHeading size={18} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Heading 3" arrow>
          <IconButton
            onClick={() => handleClick('toggleHeading', { level: 3 })}
            color={editor.isActive('heading', { level: 3 }) ? 'primary' : 'default'}
          >
            <FaHeading size={18} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Bullet List" arrow>
          <IconButton
            onClick={() => handleClick('toggleBulletList')}
            color={editor.isActive('bulletList') ? 'primary' : 'default'}
          >
            <FaListUl size={18} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Ordered List" arrow>
          <IconButton
            onClick={() => handleClick('toggleOrderedList')}
            color={editor.isActive('orderedList') ? 'primary' : 'default'}
          >
            <FaListOl size={18} />
          </IconButton>
        </Tooltip>
      </Box>
      <Box>
        <Tooltip title="Undo" arrow>
          <IconButton onClick={() => handleClick('undo')}>
            <FaUndo size={18} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Redo" arrow>
          <IconButton onClick={() => handleClick('redo')}>
            <FaRedo size={18} />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export const Tiptap = ({ value, setDescription }) => {
  const editor = useEditor({
    extensions: [StarterKit, Underline],
    content: value,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      setDescription(html);
    },
  });

  useEffect(() => {
    if (editor) {
      editor.commands.setContent(value); // Update content when value prop changes
    }
  }, [value, editor]);

  return (
    <Box
      sx={{
        borderRadius: '5px',
        border: '1px solid rgba(128, 128, 128, 0.3)',
        backgroundColor: 'white',
        minHeight: '20rem',
      }}
    >
      <MenuBar editor={editor} />
      <Box
        sx={{
          padding: '10px',
          borderTop: '1px solid rgba(128, 128, 128, 0.3)',
          borderRadius: '0 0 5px 5px',
          '.ProseMirror': {
            padding: '10px',
            minHeight: '20rem',
            lineHeight: '1.5', // Adjust line-height
          },
          '.ProseMirror p': {
            margin: '0', // Remove margin for paragraphs
          },
          '.ProseMirror:focus': {
            outline: 'none',
          },
          '.ProseMirror ul, .ProseMirror ol': {
            padding: '0 2rem',
          },
          '.ProseMirror h1, .ProseMirror h2, .ProseMirror h3, .ProseMirror h4, .ProseMirror h5, .ProseMirror h6': {
            lineHeight: 1,
            margin: '0.5rem 0', // Adjust margin for headers
          },
          '.ProseMirror code': {
            backgroundColor: 'rgba(97, 97, 97, 0.1)',
            color: 'rgba(97, 97, 97, 0.9)',
          },
          '.ProseMirror pre': {
            backgroundColor: '#0d0d0d',
            color: '#fff',
            fontFamily: '"JetBrainsMono", monospace',
            padding: '0.75rem 1rem',
            borderRadius: '0.5rem',
          },
          '.ProseMirror img': {
            maxWidth: '100%',
            height: 'auto',
          },
          '.ProseMirror blockquote': {
            paddingLeft: '1rem',
            borderLeft: '3px solid rgba(153, 153, 153, 1)',
          },
          '.ProseMirror hr': {
            border: 'none',
            borderTop: '3px solid rgba(153, 153, 153, 1)',
            margin: '2rem 0',
          },
        }}
      >
        <EditorContent editor={editor} />
      </Box>
    </Box>
  );
};
