import React from 'react';
import { Box } from '@mui/material';

function BacteriaAnalyzeColors() {
  return (
    <Box
      sx={{
        display: 'flex',
        width: 'max-content',
        gap: 0.2,
        mt: 1,
      }}
    >
      <Box
        sx={{
          bgcolor: 'rgba(255, 0, 0, 1)',
          py: 0.6,
          borderRadius: 0.7,
          width: '1.2rem'
        }}
      />
      <Box
        sx={{
          bgcolor: 'rgba(255, 165, 0, 1)',
          py: 0.6,
          borderRadius: 0.7,
          width: '1.2rem'
        }}
      />
      <Box
        sx={{
          bgcolor: 'rgba(255, 191, 0, 1)',
          py: 0.6,
          borderRadius: 0.7,
          width: '1.2rem'
        }}
      />
      <Box
        sx={{
          bgcolor: 'rgba(135, 206, 235, 1)',
          py: 0.6,
          borderRadius: 0.7,
          width: '1.2rem'
        }}
      />
      <Box
        sx={{
          bgcolor: 'rgba(0, 128, 0, 1)',
          py: 0.6,
          borderRadius: 0.7,
          width: '1.2rem'
        }}
      />
    </Box>
  );
}

export default BacteriaAnalyzeColors;
