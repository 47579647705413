import PropTypes from 'prop-types';
// material-ui
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// project import
import Dot from './@extended/Dot';
import { fontStyle } from '../pages/dashboard/utils';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { getAuthToken } from '../utils/jwtUtils';
import { useSelector } from 'react-redux';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


// ==============================|| Device TABLE - HEADER ||============================== //

function DevicesTableHead({ order, orderBy, activeSensor }) {
  const { t } = useTranslation()
  const headCells = [
    {
      id: 'id',
      label: t('tracking_no'),
    },
    {
      id: 'device',
      label: t('device_log'),
    },
    {
      id: 'status',
      label: activeSensor ? activeSensor : t('device_status'),
    },
  ];
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false} sx={{ ...fontStyle }}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

DevicesTableHead.propTypes = { order: PropTypes.any, orderBy: PropTypes.string, activeSensor: PropTypes.any };

function OrderStatus({ status }) {
  const { t } = useTranslation()
  let color;
  let title;

  switch (status) {
    case 0:
      color = 'warning';
      title = t('pending');
      break;
    case 1:
      color = 'success';
      title = t('completed');
      break;
    case 2:
      color = 'error';
      title = t('in_route');
      break;
    default:
      color = 'primary';
      title = t('none');
  }

  return (
    <Stack direction='row' spacing={1} alignItems='center'>
      <Dot color={color} />
      <Typography sx={{ ...fontStyle, fontWeight: 200, fontSize: '0.9rem' }}>{title}</Typography>
    </Stack>
  );
}

OrderStatus.propTypes = { status: PropTypes.number.isRequired };

// ==============================|| ORDER TABLE ||============================== //

export default function DevicesTable({ active }) {

  const order = 'asc';
  const orderBy = 'id';
  const token = getAuthToken()
  const { currentFacility } = useSelector((state) => state.facility);
  const [devices, setDevices] = useState([])

  const getDevices = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/devices?facility=${currentFacility.facilityId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!res.ok) {
        const errorData = await res.json();
        console.error('Error:', errorData);
        return null;
      }

      const data = await res.json();
      setDevices(data)

    } catch (error) {
      console.error('Fetch error:', error);
      return null;
    }
  };

  useEffect(() => {
    getDevices()
  }, [currentFacility])

  return (
    <Box sx={{ width: '100%', overflow: 'auto', minHeight: 400 }}>
      <TableContainer
        sx={{
          width: '100%',
          minHeight: 400,
          overflow: 'auto',
        }}
      >
        <Table stickyHeader aria-labelledby='table'>
          <DevicesTableHead activeSensor={active} order={order} orderBy={orderBy} />
          <TableBody>
            {stableSort(devices, getComparator(order, orderBy)).map((device, index) => {
              return (
                <TableRow
                  hover
                  role='checkbox'
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  tabIndex={-1}
                  key={index}
                >
                  <TableCell sx={{ ...fontStyle, fontWeight: 200, fontSize: '0.9rem' }}>
                    {index + 1}
                  </TableCell>
                  <TableCell sx={{ ...fontStyle, fontWeight: 200, fontSize: '0.9rem' }}>{device.nodeName}</TableCell>
                  <TableCell>
                    <OrderStatus status={0} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
