import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAuthToken, verifyAuthToken } from '../utils/jwtUtils';
import Spinner from './Spinner';

function PrivateRoute({ children }) {
  const { currentUser } = useSelector(state => state.user);
  const token = getAuthToken();
  const location = useLocation();
  const [isTokenValid, setIsTokenValid] = useState(null);

  useEffect(() => {
    const checkToken = async () => {
      if (token) {
        const valid = await verifyAuthToken(token);
        setIsTokenValid(valid);
      } else {
        setIsTokenValid(false);
      }
    };

    checkToken();
  }, [token]);

  if (isTokenValid === null) {
    // While checking the token, render nothing or a loading spinner
    return <Spinner />;
  }

  if (!currentUser || !isTokenValid) {
    // Redirect to login page if user is not authenticated or token is invalid
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export default PrivateRoute;
