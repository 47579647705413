import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import React, { useEffect, useState } from 'react';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function TrendChart({ data }) {
  const [legendPosition, setLegendPosition] = useState('right');

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        reverse: false,
      },
    },
    plugins: {
      legend: {
        position: legendPosition,
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },

  };

  useEffect(() => {
    const updateLegendPosition = () => {
      if (window.innerWidth < 900) {
        setLegendPosition('top');
      } else {
        setLegendPosition('right');
      }
    };

    // Initial check
    updateLegendPosition();

    // Add event listener for window resize
    window.addEventListener('resize', updateLegendPosition);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateLegendPosition);
    };
  }, []);

  return <Line options={options} data={data} />;
}

export default TrendChart;
