import React, { useEffect, useState } from 'react';
import 'react-multi-carousel/lib/styles.css';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// project import
import MainCard from './../../components/MainCard';
import MonthlyPieChart from './components/MonthlyPieChart';
import OverviewBox from '../../components/OverviewBox';

import { fontStyle } from './utils';
import CustomSwiper from './swiper';
import DevicesTable from '../../components/DevicesTable';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { months } from './utils';
import { Box, FormControl, MenuItem, Select } from '@mui/material';
import { getAuthToken } from '../../utils/jwtUtils';
import BacterialLevelChart from './components/BacterialLevelChart';
import AlarmsDisplay from './components/AlarmsDisplay';

// ==============================|| DASHBOARD - DEFAULT ||============================== //

function Dashboard() {
  const token = getAuthToken();
  const { t } = useTranslation();
  const { currentFacility } = useSelector((state) => state.facility);
  const [selectedMonth, setSelectedMonth] = useState(months.find((month) => month.name === 'Total'));
  const [culturingAQIStats, setCulturingAQIStats] = useState([
    {
      title: 'avg_bacteria_level',
      value: 0,
    },
    {
      title: 'max_bacteria_level',
      value: 0,
    },
    {
      title: 'min_bacteria_level',
      value: 0,
    },
  ]);

  // Function to handle onChange for select field
  const handleMonthSelect = (e) => {
    const selectedMonthName = e.target.value;
    const monthObject = months.find((month) => month.name === selectedMonthName);
    setSelectedMonth(monthObject);
  };

  const fetchCulturingHistoryDayStats = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/culturingDayHistory`, {
        method: 'POST', // Use POST method
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          startDate: selectedMonth.startDate,
          endDate: selectedMonth.endDate,
          facilityId: currentFacility.facilityId,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      // Process and map the data to the culturingAQIStats structure
      const updatedStats = [
        {
          title: 'avg_bacteria_level',
          value: data[0].averageAqi,
        },
        {
          title: 'max_bacteria_level',
          value: data[0].maxAqi,
        },
        {
          title: 'min_bacteria_level',
          value: data[0].minAqi,
        },
      ];

      // Update the state with the new stats
      setCulturingAQIStats(updatedStats);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  useEffect(() => {
    fetchCulturingHistoryDayStats();
  }, [selectedMonth, currentFacility]);

  return (
    <Grid container rowSpacing={2.5} columnSpacing={2.75} sx={{ mb: 1 }}>
      {/* Head */}
      <Grid item xs={12} sx={{ mb: -2.25 }}>
        <Typography sx={{ ...fontStyle }} variant='h5'>
          {t('dashboard')}
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ mb: -2.25 }}>
        <Grid container columnGap={2.75} sx={{ alignItems: 'center' }}>
          <Typography
            sx={{
              ...fontStyle,
            }}
            variant='h5'
          >
            {t('monthly_overview')}
          </Typography>
          <FormControl sx={{ minWidth: 150, mb: 2 }} size='small'>
            <Select
              labelId='demo-select-small-label'
              id='demo-select-small'
              value={selectedMonth.name} // Use month name for the Select value
              onChange={handleMonthSelect}
              sx={{ ...fontStyle }}
            >
              {months.map((month, i) => (
                <MenuItem key={i} value={month.name}>
                  {t(month.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Row for OverviewBoxes */}
      {culturingAQIStats.map((data, i) => (
        <Grid key={i} item xs={12} sm={6} md={4}>
          <OverviewBox data={data} />
        </Grid>
      ))}

      {/* Bacterial Line and Pie chart */}
      <Grid item xs={12} md={7} lg={8}>
        <Grid container alignItems='center' justifyContent='space-between'>
          <Grid item>
            <Typography sx={{ ...fontStyle }} variant='h5'>
              {t('bacteria_level')}
            </Typography>
          </Grid>
        </Grid>
        <MainCard sx={{ mt: 1.5 }} content={false}>
          <Box sx={{ pt: 1, pr: 2, height: '400px' }}>
            <BacterialLevelChart slot={'day'} date={selectedMonth} />
          </Box>
        </MainCard>
      </Grid>
      <Grid item xs={12} md={5} lg={4}>
        <Grid container alignItems='center' justifyContent='space-between'>
          <Grid item>
            <Typography sx={{ ...fontStyle }} variant='h5'>
              {t('monthly_statistics')}
            </Typography>
          </Grid>
        </Grid>
        <MainCard sx={{ mt: 1.5, height: '400px' }} content={false}>
          <MonthlyPieChart date={selectedMonth} />
        </MainCard>
      </Grid>

      {/* Sensor Line Charts in swiper caraousel */}
      {/* This is my carousel here */}
      <Grid item sx={{ mt: 1 }} xs={12}>
        <Grid item sx={{ mb: 1.5 }}>
          <Typography sx={{ ...fontStyle }} variant='h5'>
            {t('air_quality')}
          </Typography>
        </Grid>
        <CustomSwiper date={selectedMonth} />
      </Grid>

      {/* Devices Display */}
      <Grid item xs={12} md={7} lg={8}>
        <Grid container alignItems='center' justifyContent='space-between'>
          <Grid item>
            <Typography sx={{ ...fontStyle }} variant='h5'>
              {t('devices')}
            </Typography>
          </Grid>
        </Grid>

        {/* DO not remove */}
        {/* <Grid item sx={{ my: 1.5, display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ ...fontStyle }} variant='h5'>
            TOTAL DEVICES
          </Typography>
          <Typography sx={{ ...fontStyle }} variant='h5'>
            NORMAL DEVICES
          </Typography>
          <Typography sx={{ ...fontStyle }} variant='h5'>
            UNNORMAL DEVICES
          </Typography>
        </Grid> */}

        <MainCard sx={{ mt: 2 }} content={false}>
          <DevicesTable />
        </MainCard>
      </Grid>

      <Grid item xs={12} md={5} lg={4}>
        <Grid container alignItems='center' justifyContent='space-between'>
          <Grid item>
            <Typography sx={{ ...fontStyle }} variant='h5'>
              {t('alarms')}
            </Typography>
          </Grid>
        </Grid>
        <MainCard sx={{ mt: 2, overflowY: 'auto', height: '400px' }} content={false}>
          {[1, 2, 3].map((alarm, alarmId) => (
            <AlarmsDisplay key={alarmId} alarm={{ alarm, alarmId }} />
          ))}
        </MainCard>
      </Grid>
    </Grid>
  );
}

export default Dashboard;
