import React from 'react';
import { Box } from '@mui/material';

function DisplayColorFulTable() {
  return (
    <Box
      component="ul"
      sx={{
        backgroundColor: '#ffffff',
        p: 0.4,
        borderRadius: 1,
        width: '4rem',
        listStyle: 'none',
        m: 0,
      }}
    >
      <Box
        component="li"
        sx={{
          backgroundColor: 'rgba(255, 0, 0, 1)',
          py: 0.7,
          borderRadius: 0.8,
          mb: 0.4,
        }}
      ></Box>
      <Box
        component="li"
        sx={{
          backgroundColor: 'rgba(255, 165, 0, 1)',
          py: 0.7,
          borderRadius: 0.8,
          mb: 0.4,
        }}
      ></Box>
      <Box
        component="li"
        sx={{
          backgroundColor: 'rgba(255, 193, 7, 1)',
          py: 0.7,
          borderRadius: 0.8,
          mb: 0.4,
        }}
      ></Box>
      <Box
        component="li"
        sx={{
          backgroundColor: 'rgba(0, 191, 255, 1)',
          py: 0.7,
          borderRadius: 0.8,
          mb: 0.4,
        }}
      ></Box>
      <Box
        component="li"
        sx={{
          backgroundColor: 'rgba(0, 128, 0, 1)',
          py: 0.7,
          borderRadius: 0.8,
        }}
      ></Box>
    </Box>
  );
}

export default DisplayColorFulTable;
