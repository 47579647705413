import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, Link as MuiLink } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { postQuestionStart, postQuestionSuccess } from '../../redux/question/questionSlice';
import { Tiptap } from '../../components/tiptap/TextEditer'; // Assuming Tiptap is your custom component
import { getAuthToken } from '../../utils/jwtUtils';
import { postAnswerFailure } from '../../redux/answer/answerSlice';
import { openPopup } from '../../redux/popup/popupSlice';
import { setAlert } from '../../redux/alert/alertSlice';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const QAWritingPage = () => {
  const { t } = useTranslation();
  const { currentUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const token = getAuthToken();

  const [newQuestion, setNewQuestion] = useState({
    title: '',
    question: '',
  });

  const [isDisabled, setIsDisabled] = useState(true);


  const handleChange = (e) => {
    const { name, value } = e.target ? e.target : e;
    setNewQuestion({ ...newQuestion, [name]: value });
  };

  useEffect(() => {
    setIsDisabled(!newQuestion.title || !newQuestion.question || newQuestion.question.trim() === '<p></p>');
  }, [newQuestion]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      dispatch(postQuestionStart());
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/questions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ...newQuestion, userId: currentUser?.id }),
      });
      const data = await res.json();

      if (!res.ok) {
        dispatch(
          openPopup({
            title: 'Could not post your question!',
            message: data.message || 'Something went wrong, Could not post your question!',
          })
        );
        dispatch(postAnswerFailure(data.message));
        return;
      }
      dispatch(setAlert({ type: 'success', message: 'Question has been posted successfully!' }));
      dispatch(postQuestionSuccess(data));
    } catch (err) {
      dispatch(postAnswerFailure(err.message));
    } finally {
      setNewQuestion({ title: '', question: '' });
    }
  };

  return (
    <Box sx={{ width: { xs: '100%', md: '90%' }, mt: 4, mx: 'auto', bgcolor: 'rgba(255, 255, 255, 1)', borderRadius: 1, boxShadow: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 2, px: 2 }}>
        <Typography variant="h4" fontWeight="bold">
          Q/A <Typography component="span" fontWeight="normal">{t('writing')}</Typography>
        </Typography>
        <Box sx={{ borderBottom: '1px solid rgba(0, 0, 0, 1)', mr: 2 }}>
          <MuiLink component={Link} to="/questionList" underline="none" color="rgba(0, 0, 0, 1)">
            {t('question_list')}
          </MuiLink>
        </Box>
      </Box>
      <Box sx={{ py: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', pl: 2, pr: 2 }} >
          <Box sx={{ width: 'max-content', mr: 2 }}>
            <Typography >{t('content')}</Typography>
          </Box>

          <TextField
            required
            variant="outlined"
            value={newQuestion.title}
            onChange={handleChange}
            type="text"
            name="title"
            id="title"
            inputProps={{ minLength: 4, maxLength: 68 }}
            placeholder={t('enter_title')}
            sx={{ flex: 1 }}
          />
        </Box>
      </Box>
      <Box component="form" onSubmit={handleSubmit} sx={{ p: 2 }}>
        <Tiptap required value={newQuestion?.question} setDescription={(value) => handleChange({ target: { name: 'question', value } })} />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 2, mt: 2 }}>
          <Button type="submit" variant="contained" sx={{ bgcolor: 'rgba(25, 118, 210, 1)', color: 'rgba(255, 255, 255, 1)', py: 1, px: 3 }} disabled={isDisabled}>
            {t('post')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default QAWritingPage;
