import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AuthLogin from './pages/authentication/AuthLogin';
import AuthRegister from './pages/authentication/AuthRegister';
import Home from './pages/home/Home';
import AuthForgetPassword from './pages/authentication/AuthForgetPassword';
import { useDispatch, useSelector } from 'react-redux';
import AlertUser from './components/AlertUser';
import { useEffect } from 'react';
import './index.css';
import { clearAlert } from './redux/alert/alertSlice';
import Popup from './components/Popup';
import PrivateRoute from './components/PrivateRoute';

// google-fonts

import '@fontsource/public-sans/400.css';
import '@fontsource/public-sans/500.css';
import '@fontsource/public-sans/600.css';
import '@fontsource/public-sans/700.css';
import AuthResetPassword from './pages/authentication/AuthResetPassword';

function App() {
  const dispatch = useDispatch();
  const alertState = useSelector((state) => state.alert);
  const { open } = useSelector((state) => state.popup);

  useEffect(() => {
    // Clear alert after displaying it for a few seconds
    if (alertState.message) {
      const timer = setTimeout(() => {
        dispatch(clearAlert());
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [alertState, dispatch]);

  return (
    <BrowserRouter>
      {open && <Popup />}
      {alertState.message && <AlertUser alert={alertState.type} message={alertState.message} />}
      <Routes>
        <Route path='/login' element={<AuthLogin />} />
        <Route path='/register' element={<AuthRegister />} />
        <Route path='/forgotPassword' element={<AuthForgetPassword />} />
        <Route path='/resetPassword/:token' element={<AuthResetPassword />} />
        <Route
          path='/*'
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
