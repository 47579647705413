import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { fontStyle } from '../pages/dashboard/utils';

// i18n
import i18n from './../i18n'

// Flag images
import usFlag from './../assets/flags/us.jpg';
import krFlag from './../assets/flags/korea.jpg';
import { Box } from '@mui/material';

export default function LanguagesMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState({ name: 'Korean', flag: krFlag });
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (language, flag) => {
    if (language) {
      setSelectedItem({ name: language, flag });
      changeLanguage(language);
    }
    setAnchorEl(null);
  };

  const changeLanguage = (language) => {
    switch (language) {
      case 'English':
        i18n.changeLanguage('eng');
        break;
      case 'Korean':
        i18n.changeLanguage('kr');
        break;
      default:
        i18n.changeLanguage('kr');
    }
  };

  useEffect(() => {
    changeLanguage('Korean');
  }, []);

  return (
    <Box sx={{
      borderRadius: 1,
      '&:hover': {
        backgroundColor: '#e0e0e0',
      },
    }}>
      <Button
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ minWidth: 'max-content' }}
      >
        <img src={selectedItem.flag} alt={selectedItem.name} height="16" />
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose(null, null)}
        TransitionComponent={Fade}
        sx={{ ...fontStyle }}
      >
        <MenuItem
          sx={{ ...fontStyle }}
          onClick={() => handleClose('English', usFlag)}
        >
          <img src={usFlag} alt="English" height="12" style={{ marginRight: '8px' }} />
          English
        </MenuItem>
        <MenuItem
          sx={{ ...fontStyle }}
          onClick={() => handleClose('Korean', krFlag)}
        >
          <img src={krFlag} alt="Korean" height="12" style={{ marginRight: '8px' }} />
          Korean
        </MenuItem>
        {/* Add more MenuItems here for additional languages */}
      </Menu>
    </Box>
  );
}
