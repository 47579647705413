import React, { useEffect, useState } from 'react';
import { Box, Typography, TableContainer, Link as MuiLink } from '@mui/material';
import { columns } from './utils';
import { getAuthToken } from '../../utils/jwtUtils';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import MyTable from '../../components/Table';
import { useTranslation } from 'react-i18next';

const QAListPage = () => {
  const { t } = useTranslation();
  const [dbData, setDbData] = useState([]);
  const { currentUser } = useSelector((state) => state.user);
  const token = getAuthToken();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/questions`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        const modifiedData = data.map((el, i) => {
          const date = new Date(el.createdDate);
          return {
            ...el,
            number: i + 1,
            views: 30 + i,
            registrant: currentUser?.name,
            date: `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`,
            file: 'File',
          };
        });
        setDbData(modifiedData);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [currentUser, token]);

  return (
    <Box sx={{ width: { xs: '100%', md: '90%' }, mt: 4, mx: 'auto', bgcolor: 'rgba(255, 255, 255, 1)', borderRadius: 1, boxShadow: 3, p: 2 }}>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 2, px: 2 }}>
        <Typography variant="h4" fontWeight="bold">
          Q/A <Typography component="span" fontWeight="normal">{t('list')}</Typography>
        </Typography>
        <Box sx={{ borderBottom: '1px solid rgba(0, 0, 0, 1)', mr: 2 }}>
          <MuiLink component={RouterLink} to="/question" underline="none" color="rgba(0, 0, 0, 1)">
            {t('questioning')}
          </MuiLink>
        </Box>
      </Box>

      <Box>
        {/* Header */}
        <Box sx={{ display: 'flex', alignItems: 'center', bgcolor: 'rgba(230, 230, 230, 1)', p: 2 }}>
          <Typography>{t('content')}</Typography>
        </Box>

        <Box sx={{ borderBottom: '2px solid rgba(128, 128, 128, 1)', my: 1 }} />

        {/* List Area */}
        <TableContainer >
          <MyTable columns={columns} data={dbData} />
        </TableContainer>
      </Box>
    </Box>
  );
};

export default QAListPage;
