import React from 'react';
import { Box, Collapse, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import parse from 'html-react-parser';
import { MdOutlineReply } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { postAnswerSuccess } from '../redux/answer/answerSlice';

function SubTable({ columns, answer }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const answerText = typeof answer?.answer === 'string' ? answer.answer : '';
  const dbAnswer = parse(answerText);

  const handleReReply = () => {
    dispatch(postAnswerSuccess(answer));
    navigate('/showReReply');
  };

  return (
    <>
      <TableRow sx={{ cursor: 'pointer' }} onClick={handleReReply} >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length}>
          <Collapse in timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, p: 2 }}>
              {/* Custom content for sub-row */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, ml: { xs: 2, sm: 4, lg: 6 } }}>
                <Tooltip title="Reply to admin" arrow placement="top">
                  <Box
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <MdOutlineReply size={20} style={{ transform: 'rotate(180deg)' }} />
                    <Typography variant="body2" sx={{ ml: 1 }}>
                      {typeof dbAnswer === 'object' ? dbAnswer.props.children : dbAnswer}
                    </Typography>
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default SubTable;
