// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationENG from './locales/en/translation.json';
import translationKR from './locales/kr/translation.json';

const resources = {
  eng: {
    translation: translationENG,
  },
  kr: {
    translation: translationKR,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'kr', // Set default language to Korean
    fallbackLng: 'kr', // Use Korean if detected language is not available
    interpolation: {
      escapeValue: false, // React already safes from xss
    },
    react: {
      useSuspense: true, // Enables Suspense
    },
  });

export default i18n;
