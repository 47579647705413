import React, { useState } from 'react';
import FacilityMap from './components/FacilityMap';
import { Box, FormControl, MenuItem, Select, Stack, Typography } from '@mui/material';
import { fontStyle } from '../dashboard/utils';
import BacteriaMonitoring from './components/BacteriaMonitoring';
import BacteriaAnalyze from './components/BacteriaAnalyze';
import { MapCenter, MapMarkers, sensors } from './utils';
import MainCard from '../../components/MainCard';
// import TotalDevicesTable from './components/TotalDevicesTable';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DevicesTable from '../../components/DevicesTable';

function Monitoring() {
  const { t } = useTranslation()
  const { currentFacility } = useSelector(state => state.facility)

  const [activeSensor, setActiveSensor] = useState('AQI');
  const [activeSection, setActiveSection] = useState('bacteria');

  const handleSensorClick = (event) => {
    setActiveSensor(event.target.value);
  };

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };

  return (
    <Box sx={{ display: { xs: 'block', lg: 'flex' }, gap: 2.5 }}>

      <Stack sx={{ width: { xs: '100%', lg: '50%' } }}>
        {/* MAP */}
        <MainCard>

          <FacilityMap markers={MapMarkers} center={MapCenter} />
        </MainCard>

        {/* TABLE */}
        <Box>
          {/* Form for mobile */}
          <FormControl sx={{ my: 2.5, minWidth: 200, display: { xs: 'block', sm: 'none' } }} size="small">
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={activeSensor}
              onChange={handleSensorClick}
              sx={{ ...fontStyle }}
            >
              {sensors.map((sensor, i) => (
                <MenuItem
                  key={i}
                  value={sensor.sensorName}
                  sx={{ ...fontStyle }}
                >
                  {sensor.sensorName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Form for mobile */}
          <MainCard sx={{ my: 2.5, display: { xs: 'none', sm: 'block' } }}>
            <Box sx={{ display: 'flex', gap: 2.35 }}>
              {sensors.map((sensor, i) => (
                <Typography
                  key={i}
                  onClick={() => handleSensorClick({ target: { value: sensor.sensorName } })}
                  sx={{
                    ...fontStyle,
                    cursor: 'pointer',
                    borderBottom: activeSensor === sensor.sensorName ? '1px solid blue' : '1px solid transparent',
                    transition: 'border-bottom 0.3s ease',
                    lineHeight: '1'
                  }}
                  variant='h5'
                >
                  {sensor.sensorName}
                </Typography>
              ))}
            </Box>
          </MainCard>

          <Box>
            <Typography
              sx={{
                ...fontStyle,
                display: 'inline-block',
                lineHeight: '1',
                mb: 2.5
              }}
              variant='h5'
            >
              {t(currentFacility.facilityName)} {t('devices_list')}
            </Typography>
            <MainCard content={false}>
              {/* <TotalDevicesTable active={activeSensor} data={floorDevicesData} /> */}
              <DevicesTable active={activeSensor} />
            </MainCard>
          </Box>
        </Box>
      </Stack>

      <Stack sx={{ width: { xs: '100%', lg: '50%' } }}>
        <Box sx={{ my: { xs: 2, lg: 1 }, display: 'flex', gap: 2.5 }}>
          <Typography
            onClick={() => handleSectionClick('bacteria')}
            sx={{
              ...fontStyle,
              cursor: 'pointer',
              borderBottom: activeSection === 'bacteria' ? '1px solid blue' : '1px solid transparent',
              transition: 'border-bottom 0.3s ease',
              lineHeight: '1'
            }}
            variant='h5'
          >
            {t('bacteria_analyze')}
          </Typography>
          <Typography
            onClick={() => handleSectionClick('monitoring')}
            sx={{
              ...fontStyle,
              cursor: 'pointer',
              borderBottom: activeSection === 'monitoring' ? '1px solid blue' : '1px solid transparent',
              transition: 'border-bottom 0.3s ease',
              lineHeight: '1'
            }}
            variant='h5'
          >
            {t('monitoring')}
          </Typography>
        </Box>
        {activeSection === 'bacteria' ? <BacteriaAnalyze /> : <BacteriaMonitoring />}
      </Stack>
    </Box>
  );
}

export default Monitoring;
