import React, { useEffect, useState } from 'react';
import { Box, CssBaseline } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import Topbar from '../../components/topbar';
import Sidebar from '../../components/sidebar';
import QAWritingPage from '../QAsection/QAWritingPage';
import QAListPage from '../QAsection/QAListPage';
import ShowQAPage from '../QAsection/ShowQAPage';
import ReReplyPage from '../QAsection/ReReplyPage';
import TrendPage from '../trend';
import Dashboard from '../dashboard';
import Report from '../report';
import Monitoring from '../monitoring';

function Home() {
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Initial check
    if (window.innerWidth <= 1440) {
      setSidebarVisible(false);
    } else {
      setSidebarVisible(true);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowWidth]);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const handleCloseSidebar = () => {
    if (windowWidth <= 1200 && sidebarVisible) {
      setSidebarVisible(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <CssBaseline />
      {sidebarVisible && (
        <Sidebar onToggleSidebar={toggleSidebar} onSidebarVisible={sidebarVisible} />
      )}
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', width: '80%' }}>
        <Topbar onToggleSidebar={toggleSidebar} onSidebarVisible={sidebarVisible} />
        <Box
          component="main"
          sx={{
            px: 2,
            py: 2,
            flex: 1,
            bgcolor: '#f3f4f6', // Equivalent to bg-gray-100
            overflowY: 'auto',
          }}
          onClick={handleCloseSidebar}
        >
          <Routes>
            <Route path='/' element={<Dashboard />} />
            <Route path='/monitoring' element={<Monitoring />} />
            <Route path='/trends' element={<TrendPage />} />
            <Route path='/report' element={<Report />} />
            <Route path='/question' element={<QAWritingPage />} />
            <Route path='/showQuestion' element={<ShowQAPage />} />
            <Route path='/questionList' element={<QAListPage />} />
            <Route path='/showReReply' element={<ReReplyPage />} />
          </Routes>
        </Box>
      </Box>
    </Box >
  );
}

export default Home;
